import { useToggleBasket } from './useToggleBasket';
import { useCallback } from 'react';
import { toggleArrayItem } from 'utils/helpers';
import { OrderWizardRequirement } from 'interfaces/api';
import { find, uniq } from 'lodash';
import { useBasketContext } from './BasketProvider';
import { useOrdersContext } from 'modules/orders/providers';

export const useToggleAnalysis = () => {

  const { setAllOrSelectedOrders } = useOrdersContext();
  const toggleBasket = useToggleBasket();

  const { uniqRequirements } = useBasketContext();

  return useCallback((requirement: OrderWizardRequirement, analysis: string, fromBasket?: boolean) => {

    let toggleBase = requirement.selectedAnalyses;

    if (!fromBasket) {
      const basketRequirement = find(uniqRequirements, { id: requirement.id });
      toggleBase = uniq([...requirement.selectedAnalyses, ...basketRequirement.selectedAnalyses]);
    }

    const selectedAnalyses = toggleArrayItem(toggleBase, analysis);
    if (selectedAnalyses.length > 0) {
      setAllOrSelectedOrders(order => ({
        ...order,
        requirements: order.requirements.map(r => r.id === requirement.id ? { ...r, selectedAnalyses } : r),
      }));
    } else {
      toggleBasket(requirement, fromBasket);
    }

  }, [toggleBasket, uniqRequirements, setAllOrSelectedOrders]);

};
