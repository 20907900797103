import {
  CompletionListOrderBy,
  ComposedOrderProperties,
  Feature,
  Order,
  OrderDigitalSignature,
  OrderListMode,
  OrderStatus,
  OrderWizardLocalization,
  UserType,
} from 'interfaces/api';
import { OrdersListContext } from 'modules/orders/index';
import { IconProps } from 'components';
import { faFileMedicalAlt, faFileSignature, faLightEmergencyOn, faTags, faTrashAlt, faUserFriends } from '@fortawesome/pro-light-svg-icons';
import messages from 'messages';
import { arrayify } from 'utils/helpers';
import { Color } from 'interfaces';
import { FormatDate, useConfig } from 'providers';
import { filter, max } from 'lodash';
import React, { useCallback } from 'react';
import { getListItemFlag, getOrderDoctorDisplayName, getPatientTitle, isComposedOrder, SampleInLaboratoryIcon } from 'modules/orders/utils';
import { ListLayoutTransformResponse, useGuard } from 'containers';
import { useGetListItemFlag } from 'modules/reports/providers/ReportListLayoutProvider';

export const useTransformResponse = () => {

  const config = useConfig();
  const guard = useGuard();
  const getReportListItemFlag = useGetListItemFlag();

  return useCallback<ListLayoutTransformResponse<OrdersListContext, ComposedOrderProperties | Order>>((entity, context) => {

    const { preferences: { ordersScheduleTimeEnabled, ordersShowRoomNumber } } = config;
    const icons: IconProps[] = [];

    if (isComposedOrder(entity)) {

      if (entity.orderCount > 1 && context?.mode !== OrderListMode.MassOrders) {
        if (entity.isPool) {
          icons.push({ icon: faUserFriends, tooltip: messages.orders.isPool });
        } else {
          icons.push({ icon: faTags, tooltip: messages.orders.isSplit });
        }
      }

      if (entity.reportStatus?.length > 0) {
        icons.push({
          icon: faFileMedicalAlt,
          color: getReportListItemFlag({ status: entity.reportStatus[0], pathological: max(entity.reportPathological) })[0],
          // @ts-expect-error todo
          tooltip: messages.reports.status[entity.reportStatus[0]],
        });
      }

    }

    const isEmergency = isComposedOrder(entity) && entity.emergency;

    if (entity.urgent || isEmergency) {
      const labelType = (isEmergency || entity.doctor.localisation === OrderWizardLocalization.KIS) ? 'emergency' : 'urgent';
      icons.push({ icon: faLightEmergencyOn, tooltip: messages.orders.additionalFields[labelType] });
    }

    if (entity.isSoftDeleted) {
      icons.push({ icon: faTrashAlt });
    }

    if (entity.sample_in_lab) {
      guard({ feature: Feature.SampleInLaboratory }, () => icons.push(SampleInLaboratoryIcon));
    }

    const digitalSignature = arrayify(entity.digitalSignature);

    if (digitalSignature.includes(OrderDigitalSignature.Signed)) {
      icons.push({ icon: faFileSignature, color: Color.Green, tooltip: messages.orders.digitalSignature[OrderDigitalSignature.Signed] });
    }

    if (digitalSignature.includes(OrderDigitalSignature.Unsigned)) {
      icons.push({ icon: faFileSignature, color: Color.Yellow, tooltip: messages.orders.digitalSignature[OrderDigitalSignature.Unsigned] });
    }

    const groupByValue = isComposedOrder(entity)
      ? ({
        [OrderListMode.Journal]: entity.executed_at,
        [OrderListMode.Bookmarked]: entity.scheduled_at,
        [OrderListMode.DigitalSignature]: entity.created_at,
        [OrderListMode.Completion]: context?.filters?.completionOrder === CompletionListOrderBy.ScheduleDate ? entity.scheduled_at : entity.updated_at,
      } as any)[context?.mode] || entity.updated_at
      : undefined;

    const { id } = entity;

    const title = getPatientTitle(isComposedOrder(entity) ? entity.displayName : entity.patient?.displayName);

    const doctorDisplayName = getOrderDoctorDisplayName(entity, ordersShowRoomNumber);

    const subtitle = (() => {
      switch (context?.mode) {
        case OrderListMode.Journal:
          return <span>{entity.tnr} ({entity.meta_strings?.costUnits})</span>;
        default:
          return <FormatDate date={entity.created_at}/>;
      }
    })();

    const scheduled_at = filter(isComposedOrder(entity) ? entity.scheduled_at : [entity.scheduled_at]);

    const fields = (() => {
      switch (context?.mode) {
        case OrderListMode.Journal:
          return [{
            label: messages.general.userTypes.single[UserType.ARZ],
            value: doctorDisplayName,
            narrow: true,
          }, {
            label: messages.orders.executed_at,
            value: <FormatDate date={entity.executed_at} options={{ dateOnly: true }}/>,
            narrow: true,
          }, {
            label: messages.orders.sampled_at,
            value: <FormatDate date={(entity as ComposedOrderProperties).sampled_at} options={{ dateOnly: true }}/>,
            narrow: true,
          }];
        default:
          return [{
            label: doctorDisplayName,
            value: entity.tnr || '-',
            narrow: true,
          }, {
            label: entity.meta_strings?.costUnits,
            value: entity.meta_strings?.orderFormNames,
            narrow: true,
          }, {
            label: entity.meta_strings?.materials,
            value: entity.meta_strings?.requirements,
            wide: true,
          }, {
            label: scheduled_at.length > 0 && messages.orders.scheduled_at,
            value: scheduled_at.length > 0 && (
              <ul className={'comma-seperated'}>
                {scheduled_at.map(s => (
                  <li key={s}><FormatDate date={s} options={{ dateOnly: !ordersScheduleTimeEnabled }}/></li>
                ))}
              </ul>
            ),
            narrow: true,
          }];
      }
    })();

    if (context?.mode === OrderListMode.MassOrders) {
      fields.pop();
    }

    const flag = (() => {
      switch (context?.mode) {
        case OrderListMode.Journal:
          return undefined;
        default:
          return getListItemFlag(entity);
      }
    })();

    const body = (() => {
      switch (context?.mode) {
        case OrderListMode.Journal:
          return entity.meta_strings?.journal;
        default:
          return undefined;
      }
    })();

    return {
      id,
      flag,
      icons,
      title,
      subtitle,
      body,
      groupByValue,
      fields,
      meta: entity,
      faded: arrayify(entity.status).includes(OrderStatus.Canceled),
      badge: context?.mode === OrderListMode.MassOrders ? (entity as ComposedOrderProperties).orderCount : undefined,
    };
  }, [config, getReportListItemFlag]);
};
