import React, { useMemo } from 'react';
import styles from './styles.module.less';
import { AggregatedRequirementFlags, Feature, OrderWizardRequirement, RequirementType, ResultGraphicFormat } from 'interfaces/api';
import { Container, List, ListItem, Loader } from 'components';
import { useFormatReportValue } from 'modules/reports/utils';
import cx from 'classnames';
import { useGraph } from 'modules/reports/components/Graph/Graph';
import { useBasketContext, useToggleAnalysis } from 'modules/orders/containers/OrderWizard/providers';
import { useOrdersContext } from 'modules/orders/providers';
import { filter, find } from 'lodash';
import { useFormatDate } from 'providers';
import { useGuard } from 'containers';

const Chart = React.lazy(() => import('modules/reports/components/Controls/Graphic/Chart/Chart'));

export const PopOverAnalyses = (props: { requirement: OrderWizardRequirement; basket?: boolean }) => {

  const { requirement, basket } = props;

  const { getExtendedAnalyses } = useOrdersContext();
  const { uniqRequirements } = useBasketContext();
  const toggleAnalysis = useToggleAnalysis();

  const analyses = getExtendedAnalyses(requirement);
  const formatValue = useFormatReportValue();

  const Graph = useGraph();
  const guard = useGuard();
  const formatDate = useFormatDate();

  const inBasket = find(uniqRequirements, { id: requirement.id });

  const onAnalysisSelect = (analysis: string) => {
    toggleAnalysis(inBasket || requirement, analysis, basket);
  };

  if (analyses.length === 0) {
    return null;
  }

  if (analyses.length === 1 && requirement.shortName === analyses[0].shortName && !analyses[0].history) {
    return null;
  }

  return (
    <List
      className={styles.list}
      checkboxes={requirement.flags.includes(AggregatedRequirementFlags.MultiAnalysisSelect)}
      onSelect={({ id }) => {
        if (requirement.entityType === RequirementType.Microbiological) {
          onAnalysisSelect(id + '');
        }
      }}
      disabled={requirement.flags.includes(AggregatedRequirementFlags.MultiAnalysisSelect) && requirement.selectedAnalyses?.length > 0 && !basket}
      selected={(requirement.selectedAnalyses || inBasket?.selectedAnalyses)?.map(id => ({ id }))}
      itemIsCollapse={(item) => {
        const analysis = find(analyses, { shortName: item.id + '' });

        if (analysis.history && analysis.lastValue.graphicFormat !== ResultGraphicFormat.NoGraphic) {
          return (
            <Container>
              <Container padding horizontal>
                <Container grow className={styles.graphContainer}>
                  <Graph value={analysis.lastValue}/>
                </Container>
              </Container>
              {analysis.history.values.length > 1 && (
                <React.Suspense fallback={<Loader/>}>
                  <Chart compact values={[analysis.history.values]} className={styles.chart}/>
                </React.Suspense>
              )}
            </Container>
          );
        } else {
          return undefined;
        }
      }}
      items={analyses.map((analysis): ListItem => {

        const hint = useMemo(() => guard({ feature: Feature.RequirementsHint }, () => analysis.hint), [requirement]);

        return {
          id: analysis.shortName,
          title: analysis.longName,
          subtitle: filter([
            analysis.history && formatDate(analysis.lastValue.date, { dateOnly: true }),
            requirement.flags.includes(AggregatedRequirementFlags.MultiAnalysisSelect) ? undefined : analysis.shortName,
          ]).join(' - '),
          body: hint ? <div dangerouslySetInnerHTML={{ __html: hint }} className={'mibi-analysis-hint'}/> : undefined,
          fields: analysis.history
            ? [{
              label: analysis.lastValue.reference.text + ' ' + analysis.lastValue.unit,
              value: formatValue(analysis.lastValue),
              flipped: true,
              className: cx({ [styles.isPatho]: analysis.lastValue.pathological }),
            }]
            : undefined,
        };
      })}
    />
  );

};
