import React, { useEffect, useMemo } from 'react';
import { Container, Loader, SideBar } from 'components';
import { AnimatedRoutes, RouteArray, useActiveRoute, useGuard } from 'containers';
import { Redirect, useHistory, useLocation } from 'react-router';
import cx from 'classnames';
import { Platform, Product, UserType } from 'interfaces/api';
import { useAuthStoreSelectors } from 'modules/auth/store';
import { Breakpoint } from 'interfaces';
import TopBar from './TopBar/TopBar';
import { useLegacyRoutes, useToggle } from 'hooks';
import Notifications from 'modules/notifications';
import { SplashScreen } from '@capacitor/splash-screen';
import '~antd/dist/reset.css';
import 'styles/index.less';
import './App.less';
import { Copyright } from 'containers/App/Copyright/Copyright';
import { useLinks } from 'containers/App/useLinks';
import { filter } from 'lodash';
import { useConfig, useIntlStoreSelectors } from 'providers';
import messages from 'messages';
import { withSuspense } from 'hocs';
import { StatusBar, Style } from '@capacitor/status-bar';
// @ts-expect-error todo
import SmartBanner from 'react-smartbanner';
import '~react-smartbanner/dist/main.css';
import MaintenanceNotice from './MaintenanceNotice/MaintenanceNotice';
import SystemBanner from './SystemBanner/SystemBanner';
import { useKeyboard } from 'hooks/useKeyboard';
import { useEnv, useEnvStore } from 'providers/EnvProvider';
import { useApiUpdateAvailableCheck } from 'providers/ApiProvider/useApiUpdateAvailableCheck.tsx';

const productLabels = messages.products.product;

export const App = () => {

  const guard = useGuard();

  const user = useAuthStoreSelectors.user();
  const legacy = useAuthStoreSelectors.legacy();

  const platform = useEnv.platform();

  const translate = useIntlStoreSelectors.translate();
  const { TITLE, TITLE_SEPARATOR, APP_NAME, STATUS_BAR_DARK_MODE } = useEnvStore.getState();
  const { quickAccess, serverId, system } = useConfig();

  const { pathname, search, state: locationState } = useLocation<{ redirectTo?: string }>();
  const history = useHistory();

  useKeyboard();
  useApiUpdateAvailableCheck();
  useLegacyRoutes();

  const navItems = useMemo(() => new RouteArray([
    {
      path: '/auth',
      navHidden: true,
      noTopBar: true,
      component: withSuspense(React.lazy(() => import('modules/auth/index'))),
    },
    quickAccess?.enabled
      ? {
        path: quickAccess.path,
        navHidden: true,
        noTopBar: true,
        component: withSuspense(React.lazy(() => import('modules/reports/containers/QuickAccess'))),
      }
      : undefined,
    {
      path: '/guid/:guid/:key',
      navHidden: true,
      render: routeParams => (
        <Redirect to={{ pathname: '/auth/login', state: { scanData: routeParams.match.params } }}/>
      ),
    },
    {
      path: '/dashboard',
      title: productLabels[Product.DASHBOARD].title,
      guard: { product: Product.DASHBOARD },
      component: withSuspense(React.lazy(() => import('modules/dashboard/index'))),
    },
    {
      path: '/of',
      title: productLabels[Product.OF].title,
      guard: { product: Product.OF },
      component: withSuspense(React.lazy(() => import('modules/cases/index'))),
    },
    {
      path: '/ob',
      title: productLabels[Product.OB].title,
      guard: { product: Product.OB },
      component: withSuspense(React.lazy(() => import('modules/reports/index'))),
    },
    {
      path: '/oa',
      title: productLabels[Product.OA].title,
      guard: { product: Product.OA },
      component: withSuspense(React.lazy(() => import('modules/orders/index'))),
    },
    {
      path: '/le',
      title: productLabels[Product.LE].title,
      guard: { product: Product.LE },
      component: withSuspense(React.lazy(() => import('modules/exchange/index'))),
    },
    {
      path: '/ll',
      title: productLabels[Product.LL].title,
      guard: { product: Product.LL },
      component: withSuspense(React.lazy(() => import('modules/lexika/index'))),
    },
    {
      path: '/ls',
      title: productLabels[Product.LS].title,
      guard: { product: Product.LS },
      component: withSuspense(React.lazy(() => import('modules/store/index'))),
    },
    {
      path: '/messages',
      title: messages.general.messageCenter,
      guard: { permitted: [UserType.LAB, UserType.LAU, UserType.ARZ, UserType.PAT] },
      navHidden: true,
      component: withSuspense(React.lazy(() => import('modules/messages/index'))),
    },
    {
      path: '/admin',
      title: messages.general.administration,
      guard: { forbidden: [UserType.PAT], product: Product.ADMIN },
      navClassName: 'is-hidden-tablet-up',
      component: withSuspense(React.lazy(() => import('modules/admin/index'))),
    },
  ]), [quickAccess]);

  useEffect(() => {
    if (user && pathname.startsWith('/auth') && !legacy) {
      history.replace('/');
    }
  }, [user]);

  useEffect(() => {
    SplashScreen.hide();
  }, []);

  useEffect(() => {
    if (platform !== Platform.WEB) {
      StatusBar.setStyle({ style: STATUS_BAR_DARK_MODE ? Style.Dark : Style.Light });
    }
  }, [STATUS_BAR_DARK_MODE]);

  useEffect(() => {

    if (!serverId) {
      // /config must be loaded
      return;
    }

    const unguardedPaths = [...navItems.filter(item => !item?.guard).map(i => i.path), '/index.php', '/guid'];
    const isGuardedPath = unguardedPaths.filter(paths => pathname.startsWith(paths)).length === 0;

    if (!user && isGuardedPath) {
      history.replace({
        search,
        pathname: '/auth/login',
        state: { redirectTo: pathname },
      });
    }
  }, [user, pathname, serverId]);

  useEffect(() => {
    const navTitle = navItems.filter(i => pathname.startsWith(i.path))[0]?.title;
    document.title = filter([translate(navTitle), TITLE]).join(TITLE_SEPARATOR);
  }, [pathname]);

  const sideBarToggle = useToggle(false);
  const notificationToggle = useToggle(false);

  useEffect(() => {
    if (notificationToggle[0]) {
      notificationToggle[1](false);
    }
  }, [pathname]);

  useEffect(() => {
    if (user && !legacy) {

      if (pathname.startsWith('/auth') || pathname === '/') {

        const availableProducts = filter(Product, product => !!guard({ product }, () => product));

        const startProductKey = user.preferences.startProduct || availableProducts[0];
        const product = guard({ product: startProductKey }, () => startProductKey) || availableProducts[0];

        const redirectTo = locationState?.redirectTo;
        const path = redirectTo && redirectTo !== '/' ? redirectTo : '/' + product;
        history.replace(path);
      }
    }
  }, [guard, user, pathname]);

  const containerStyles = useMemo(() => cx('app-wrapper', {
    'auth-wrapper': !user,
  }), [!!user]);

  const sideBarLogo = useMemo(() => (
    <div key={'logo'} className={'sidebar-logo-container'}>
      <div className={'sidebar-logo-container'}/>
    </div>
  ), []);

  const additionalLinks = useLinks();
  const activeRoute = useActiveRoute(navItems);

  const sideBarMeta = useMemo(() => ([
    additionalLinks.length > 0 ? <ul key={'additionalLinks'} className={'additional-links'}>{additionalLinks}</ul> : null,
    <ul key={'copyright'}><Copyright className={'copyright'}/></ul>,
  ]), [additionalLinks]);

  const activeItem = useMemo(() => activeRoute.getActive(), [pathname]);

  return (
    <>

      {platform === Platform.WEB && <SmartBanner title={APP_NAME}/>}

      <SideBar
        items={[sideBarLogo, ...navItems, ...sideBarMeta]}
        inverted
        toggle={sideBarToggle}
        className={'app-sidebar'}
        breakpoint={Breakpoint.DesktopDown}
      />

      <Container grow className={containerStyles} withSideBar={{ toggle: sideBarToggle, breakpoint: Breakpoint.DesktopDown }}>

        <SystemBanner/>
        {user && system?.maintenance && <MaintenanceNotice/>}

        {user && !activeItem?.[0]?.noTopBar && <TopBar items={navItems} toggle={sideBarToggle} notificationToggle={notificationToggle}/>}

        <main className={'app-container'}>
          <AnimatedRoutes routes={navItems}/>
          {user && <Notifications toggle={notificationToggle}/>}
        </main>

        {(legacy || pathname.startsWith('/index.php')) && (
          <Container className={'legacy-loader'}>
            <Loader/>
          </Container>
        )}

      </Container>

    </>
  );
};
