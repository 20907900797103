import React, { useEffect, useState } from 'react';
import { useApi, useBiometrics, useIntlStoreSelectors, useLogger } from 'providers';
import { LogLevel } from 'interfaces/api';
import { useAsync } from 'react-use';
import { App } from '@capacitor/app';
import { SplashScreen } from '@capacitor/splash-screen';
import { useAuthStoreSelectors } from 'modules/auth/store';
import { useAutoLogin, useReloadUserDetails } from 'modules/auth/hooks';

export const AuthProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {

  const { authentication: { getUserDetails }, profile: { getUserAvatar } } = useApi();

  const locale = useIntlStoreSelectors.locale();
  const setLocale = useIntlStoreSelectors.setLocale();

  const user = useAuthStoreSelectors.user();
  const setUser = useAuthStoreSelectors.setUser();

  const lid = useAuthStoreSelectors.lid();
  const setAvatar = useAuthStoreSelectors.setAvatar();

  const legacy = useAuthStoreSelectors.legacy();

  const reloadUserDetails = useReloadUserDetails();

  const { setLogLevel } = useLogger();

  useEffect(() => {
    const userLocale = user?.locale ?? undefined;
    if (userLocale && userLocale !== locale) {
      setLocale(userLocale);
    }
  }, [user?.locale]);

  useEffect(() => {
    if (user?.debugEnabled) {
      setLogLevel(LogLevel.Verbose);
    }
  }, [user?.debugEnabled]);

  useEffect(() => {
    if (user?.id) {
      getUserAvatar({ id: user.id }, { responseType: 'blob' }).then((result) => {
        if (result?.size > 0) {
          setAvatar(result);
        }
      });
    }
  }, [user?.id]);

  const [initialLoaded, setInitialLoaded] = useState<boolean>(false);

  const auth = useAsync(async () => {
    if (legacy) {
      return undefined;
    }
    setUser(await getUserDetails());
    setInitialLoaded(true);
  }, [lid]);

  // pause and resume handling

  App.addListener('pause', async () => {
    await SplashScreen.show();
  });

  App.addListener('resume', async () => {
    await reloadUserDetails();
    await SplashScreen.hide();
  });

  const autoLogin = useAutoLogin();
  const biometrics = useBiometrics();

  useEffect(() => {
    if (biometrics.enabled && initialLoaded) {
      autoLogin();
    }
  }, [initialLoaded]);

  if (auth.loading) {
    return null;
  }

  if (auth.error) {
    throw auth.error;
  }

  return children;

};
